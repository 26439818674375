var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import '../../../assets/styles/scss/pages/about.scss';
export var About = function () {
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: 'about-us' }, { children: _jsxs("div", __assign({ className: 'description' }, { children: [_jsx("h3", { children: "About us" }), _jsx("p", { children: "The Digital City is a city that is connected to the net and is equipped with technological platforms for information and communications management that can enable the Internet of Things. These platforms also make it possible to process huge amounts of data and information, offering new services to city dwellers as well as new functionality in managing the urban environment." }), _jsx("p", { children: "\u2013 Czech Republic, Brno" })] })) })) }));
};
