import city from '../images/pages/home/city.svg'
import cityCompressed from '../images/pages/home/cityCompressed.jpeg'
import logo from '../images/logo.png'

import support from '../images/pages/home/support.svg'
import smartCity from '../images/pages/home/smartCity.svg'
import team from '../images/pages/home/team.svg'
import papers from '../images/pages/home/papers.svg'
import study from '../images/pages/home/study.svg'

import email from '../images/particals/footer/email.svg'
import location from '../images/particals/footer/location.svg'
import phone from '../images/particals/footer/phone.svg'

import car from '../images/pages/collaborations/car.svg'
import hierarchical from '../images/pages/collaborations/hierarchical.svg'
import taxi from '../images/pages/collaborations/taxi.svg'

import preview from '../images/pages/blogs/preview.svg'
import navigationArrow from '../images/pages/blogs/components/arrow.svg'

import success from '../images/particals/feedback/success.svg'

import menu from '../images/particals/header/menu.png'
import close from '../images/particals/header/close.png'

// Maps
import clsutersLineplots from '../images/pages/maps/clsuters_lineplots.png'
import clustersMeanActivity from '../images/pages/maps/clusters_mean_activity.png'

const images = {
  clsutersLineplots,
  clustersMeanActivity,
  city,
  cityCompressed,
  logo,
  support,
  smartCity,
  team,
  papers,
  study,
  email,
  location,
  phone,
  car,
  hierarchical,
  taxi,
  preview,
  navigationArrow,
  success,
  menu,
  close
}

export default images