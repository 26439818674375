import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { locale } from 'primereact/api';
import { Pages } from './shared/pages/Pages';
import { Notifications } from './shared/components/particals/notifications/Notifications';
import { appUnilsInit } from './utils/initialization/app';
import './App.scss';
appUnilsInit();
export var App = function () {
    // Устанавливаем язык приложения
    var lang = useSelector(function (state) { return state.language; }).lang;
    locale(lang);
    return (_jsxs(_Fragment, { children: [_jsx(Notifications, {}), _jsx(Pages, {})] }));
};
