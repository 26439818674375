import axios from "axios";
var path = function (path) { return process.env.SERVER_API + path; };
// MAPS
var getMaps = function () {
    return axios.get(path('/get-maps'));
};
var loadMap = function (filename) {
    return axios.get(path('/get-map/' + filename));
};
export var API = {
    loadMap: loadMap,
    getMaps: getMaps
};
