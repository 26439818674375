var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import '../../../../assets/styles/scss/particles/footer.scss';
export var Footer = function () {
    var year = new Date().getFullYear();
    year = year === 2022 ? year : "2022 - ".concat(year);
    return (_jsx("footer", __assign({ className: 'main-footer' }, { children: _jsxs("span", __assign({ className: 'description' }, { children: ["Built with love by ", _jsx("span", __assign({ className: 'line' }, { children: "SOCOS Team" })), " \u00A9", year] })) })));
};
