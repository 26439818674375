var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import '../../../../assets/styles/scss/particles/header.scss';
import images from '../../../../assets/images/imgs';
export var Header = function () {
    var _a = __read(useState(false), 2), isMenuActive = _a[0], setIsMenuActive = _a[1];
    return (_jsxs("header", __assign({ className: 'main-header' }, { children: [_jsxs("div", __assign({ className: 'cover' }, { children: [_jsxs(NavLink, __assign({ to: "/", className: 'logo' }, { children: [_jsx("img", { src: images.logo, alt: "logo" }), _jsx("span", { children: "Digital City" })] })), _jsxs("ul", { children: [_jsx("li", { children: _jsx(NavLink, __assign({ to: "/" }, { children: "home" })) }), _jsx("li", { children: _jsx(NavLink, __assign({ to: "/maps" }, { children: "maps" })) }), _jsx("li", { children: _jsx(NavLink, __assign({ to: "/about" }, { children: "about" })) })] }), _jsx("button", __assign({ onClick: function () { return setIsMenuActive(!isMenuActive); }, className: 'menu-button' }, { children: _jsx("img", { src: isMenuActive ? images.close : images.menu, alt: "menu" }) }))] })), isMenuActive ? (_jsx("section", __assign({ className: 'burger-menu' }, { children: _jsxs("ul", { children: [_jsx("li", __assign({ onClick: function () { return setIsMenuActive(!isMenuActive); } }, { children: _jsx(NavLink, __assign({ to: "/" }, { children: "home" })) })), _jsx("li", __assign({ onClick: function () { return setIsMenuActive(!isMenuActive); } }, { children: _jsx(NavLink, __assign({ to: "/maps" }, { children: "maps" })) })), _jsx("li", __assign({ onClick: function () { return setIsMenuActive(!isMenuActive); } }, { children: _jsx(NavLink, __assign({ to: "/about" }, { children: "about" })) }))] }) }))) : ''] })));
};
