import { addLocale, localeOption } from 'primereact/api';
import Parser from 'html-react-parser';
import cs from './languages/cs.json';
import en from './languages/en.json';
import { store } from '../../redux/store';
export var setLocalizations = function () {
    // Добавляем английский язык
    addLocale('en', en);
    // Добавляем русский язык
    addLocale('cs', cs);
};
export var translater = function (key) {
    var lang = store.getState().language.lang;
    return Parser(localeOption(key, lang));
};
