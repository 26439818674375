var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import '../../../assets/styles/scss/pages/home.scss';
export var Home = function () {
    var navigate = useNavigate();
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: 'home-introduction' }, { children: [_jsx("h1", { children: "Digital City Project" }), _jsxs("div", __assign({ className: 'flex gap-3' }, { children: [_jsx("button", __assign({ onClick: function () { return navigate('/maps'); }, className: 'p-button p-component p-button-outlined p-button-secondary p-button-sm border-1 px-4 font-regular' }, { children: "Maps" })), _jsx("button", __assign({ onClick: function () { return navigate('/about'); }, className: 'p-button p-component p-button-outlined p-button-secondary p-button-sm border-1 px-4 font-regular' }, { children: "About" }))] })), _jsxs("div", __assign({ className: 'description' }, { children: [_jsx("p", { children: "The Digital City is a city that is connected to the net and is equipped with technological platforms for information and communications management that can enable the Internet of Things. These platforms also make it possible to process huge amounts of data and information, offering new services to city dwellers as well as new functionality in managing the urban environment." }), _jsx("p", { children: "Digitalization is a key factor in making cities more sustainable: it enables the development of new forms of urban logistics and mobility, such as electric mobility and car sharing." }), _jsx("p", { children: "Digitalization also encourages the introduction of new safety and renewable source technologies. It also promotes the introduction of systems for withstanding extreme meteorological events." })] }))] })) }));
};
