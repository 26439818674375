var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image } from 'primereact/image';
import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { KeplerMap } from './components/KeplerMap';
import { API } from '../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import images from '../../../assets/images/imgs';
import '../../../assets/styles/scss/pages/maps.scss';
export var Maps = function () {
    var _a = __read(useState(undefined), 2), selectedMap = _a[0], setSelectedMap = _a[1];
    var _b = __read(useState([]), 2), maps = _b[0], setMaps = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(''), 2), selectedType = _d[0], setSelectedType = _d[1];
    var _e = __read(useState(''), 2), selectedYear = _e[0], setSelectedYear = _e[1];
    var _f = __read(useState([]), 2), types = _f[0], setTypes = _f[1];
    var _g = __read(useState([]), 2), years = _g[0], setYears = _g[1];
    // const [modalVisible, setModalVisible] = useState(false)
    var _h = useParams().type, type = _h === void 0 ? 'traffic-map' : _h;
    var navigate = useNavigate();
    var selectMap = function (map) {
        setSelectedMap(map);
        if (!map)
            return navigate("/maps", { replace: true });
        navigate("/maps/".concat(map.urlKeyword), { replace: true });
    };
    var loadMaps = function () {
        API.getMaps()
            .then(function (response) {
            var maps = response.data;
            setMaps(maps);
            var types = __spreadArray([], __read(new Set(maps.map(function (map) { return map.type; }))), false);
            var years = __spreadArray([], __read(new Set(maps.map(function (map) { return map.year; }))), false);
            setTypes(types);
            setYears(years);
            var _a = __read(years, 1), year = _a[0];
            var _b = __read(types, 1), type = _b[0];
            setSelectedYear(year);
            setSelectedType(type);
            var map = maps.find(function (map) { return map.year === year && map.type === type; });
            setSelectedMap(map);
        });
    };
    var changeType = function (type) {
        setSelectedType(type);
        var map = maps.find(function (map) { return map.year === selectedYear && map.type === type; });
        selectMap(map);
    };
    var changeYear = function (year) {
        setSelectedYear(year);
        var map = maps.find(function (map) { return map.year === year && map.type === selectedType; });
        selectMap(map);
    };
    useEffect(function () { loadMaps(); }, []);
    useEffect(function () {
        var handleQueryParamChange = function () {
            var map = maps.find(function (map) { return map.urlKeyword === type; });
            setSelectedMap(map);
        };
        handleQueryParamChange();
    }, []);
    return (_jsxs("main", __assign({ className: 'maps-page' }, { children: [_jsxs("div", __assign({ className: "card flex flex-wrap gap-3" }, { children: [_jsxs("div", __assign({ className: 'flex flex-column gap-2' }, { children: [_jsx("label", __assign({ className: 'text-600' }, { children: "Select a type" })), _jsx(Dropdown, { value: selectedType, onChange: function (e) { return changeType(e.value); }, options: types, disabled: loading, placeholder: "Select a type", className: "w-18rem" })] })), _jsxs("div", __assign({ className: 'flex flex-column gap-2' }, { children: [_jsx("label", __assign({ className: 'text-600' }, { children: "Select a year" })), _jsx(Dropdown, { value: selectedYear, disabled: loading, onChange: function (e) { return changeYear(e.value); }, options: years, placeholder: "Select a year", className: "w-18rem" })] }))] })), selectedMap && selectedMap.description
                ? (_jsx("div", __assign({ className: "card kepler-map-area" }, { children: _jsxs("div", __assign({ className: 'flex flex-column align-items-start gap-3' }, { children: [selectedMap && selectedMap.urlKeyword === 'czech-highway-load-clusters'
                                ? (_jsxs("div", __assign({ className: 'flex gap-2 flex-wrap' }, { children: [_jsx(Image, { className: 'bg-white', src: images.clsutersLineplots, imageClassName: "bg-white", downloadable: true, alt: "Image", height: "150", width: "250", preview: true }), _jsx(Image, { className: 'bg-white', src: images.clustersMeanActivity, imageClassName: "bg-white", downloadable: true, alt: "Image", height: "150", width: "250", preview: true })] })))
                                : '', _jsxs("p", __assign({ className: "m-0 description-area" }, { children: [_jsx("span", { children: "Description:" }), selectedMap && selectedMap.description || ''] }))] })) })))
                : '', _jsx("div", __assign({ className: "card kepler-map-area" }, { children: _jsx(KeplerMap, { setLoading: setLoading, map: selectedMap }) }))] })));
};
