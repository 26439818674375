var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useSWR from "swr";
import KeplerGl from 'kepler.gl';
import { useEffect } from 'react';
import { addDataToMap } from "kepler.gl/actions";
import { KeplerGlSchema } from "kepler.gl/dist/schemas";
import { useDispatch } from 'react-redux';
import { API } from '../../../../api';
import { store } from '../../../../redux/store';
import { classNames } from "primereact/utils";
export var KeplerMap = function (_a) {
    var map = _a.map, setLoading = _a.setLoading;
    var id = map ? map.id : 1;
    var dispatch = useDispatch();
    var token = process.env.API_ACCESS_TOKEN;
    var fetcher = function () { return API.loadMap(String(id)).then(function (res) { return res.data; }); };
    var path = "".concat(process.env.SERVER_API, "/map/").concat(id);
    // const { data } = useSWR(path, fetcher)
    // const { data } = useSWR(path, fetcher, { suspense: true, revalidateOnFocus: false });
    var _b = useSWR(path, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateIfStale: false,
        dedupingInterval: 60000,
        refreshInterval: 0 // Отключение автоматического обновлени,
    }), data = _b.data, isValidating = _b.isValidating;
    useEffect(function () {
        if (data) {
            var mapToLoad = KeplerGlSchema.load(data.datasets, data.config);
            dispatch(addDataToMap(mapToLoad));
            console.log(data);
        }
    }, [dispatch, data]);
    useEffect(function () {
        setLoading(isValidating);
    }, [isValidating]);
    // if (isValidating) {
    //   return <>
    //     <p>Loading...</p>
    //   </>
    // }
    return (_jsxs("div", __assign({ className: "kepler-loader-container" }, { children: [!map && _jsx("p", __assign({ className: "text-center" }, { children: "Can not find a map with this filters." })), isValidating && (_jsx("div", __assign({ className: "kepler-loader-overlay" }, { children: _jsx("div", { className: "loader" }) }))), _jsx("div", __assign({ className: classNames({
                    'hidden': !map
                }, 'kepler-map-block') }, { children: _jsx(KeplerGl, { id: "kepler", mapboxApiAccessToken: token, width: window.innerWidth, height: window.innerHeight, store: store }) }))] })));
};
