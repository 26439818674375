var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { translater } from "../../../utils/localization/localization";
import '../../../assets/styles/scss/pages/not-found.scss';
export var NotFound = function () {
    return (_jsx(_Fragment, { children: _jsxs("main", { children: [_jsxs("div", __assign({ className: 'error_message' }, { children: [_jsx("span", __assign({ className: "text-7xl" }, { children: "#404" })), _jsx("p", __assign({ className: "m-3 text-5xl mb-1" }, { children: translater("notFoundPageErrorMessage") }))] })), _jsx("div", __assign({ className: 'link-block' }, { children: _jsx(Link, __assign({ to: '/' }, { children: translater("notFoundPageLink") })) }))] }) }));
};
