import axios from "axios";
import { errorNotification } from "../../redux/actions/notificationsActions";
import { logout } from "../../redux/actions/authActions";
import { translater } from "../localization/localization";
export var initInterceptor = function () {
    axios.interceptors.response.use(
    // При успешном response
    function (response) { return response; }, 
    // При response с ошибкой
    function (error) {
        var _a, _b;
        var status = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status;
        var message = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data.message;
        var invalidServer = translater("interceptorErrorNotificationServer").toString();
        var accessDenied = translater("interceptorErrorNotification").toString();
        var notFound = translater("interceptorErrorNotification404").toString();
        // Доступ закрыт
        if (status && [403].includes(status)) {
            var msg = message || accessDenied;
            errorNotification(msg);
            return setTimeout(function () { return window.location.replace("/"); }, 1000);
        }
        // Выйти из системы если вышла сессия
        if (status && [401].includes(status))
            logout();
        // Страница не существует
        if (status && [404].includes(status))
            return errorNotification(message || notFound);
        // Показываем ошибку
        errorNotification(message ? message : invalidServer);
        return Promise.reject(error);
    });
};
