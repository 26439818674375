var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route } from "react-router-dom";
import { Home } from "./home/Home";
import { Maps } from "./maps/Maps";
import { About } from "./about/About";
import { NotFound } from "./not-found/NotFound";
import { MainLayout } from "../layouts/HomePageLayout";
export var Pages = function () {
    return (_jsxs(Routes, { children: [_jsxs(Route, __assign({ path: "/", element: _jsx(MainLayout, {}) }, { children: [_jsx(Route, { index: true, element: _jsx(Home, {}) }), _jsx(Route, { path: "maps/:type", element: _jsx(Maps, {}) }), _jsx(Route, { path: "maps", element: _jsx(Maps, {}) }), _jsx(Route, { path: "about", element: _jsx(About, {}) })] })), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }));
};
