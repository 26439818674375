import { SET_CURRENT_USER } from '../reducers/authReducer';
import { store } from '../store';
import { setAuthToken } from '../../utils/axios/token';
// Логин - получение токена пользователя
// export const login = (data: LoginRequest) => {
//   return API.login(data)
//     .then((res: AxiosResponse<LoginServerResponse>) => {
//       // Заносим токен в localStorage
//       const { token, message } = res.data
//       localStorage.setItem('token', token)
//       // Присваиваем токен в Auth header
//       setAuthToken(token)
//       // Парсим токен и получаем пользователя
//       const decoded: User = jwt_decode(token)
//       store.dispatch(setCurrentUser(decoded))
//       successNotification(message)
//     })
// }
// Вернет true, если роли совпали
export var compareRoles = function (rolesList) {
    var user = store.getState().auth.user;
    if (user) {
        var roles = user.roles;
        if (user.roles.length === 0)
            return false;
        var _loop_1 = function (i) {
            var element = rolesList[i];
            if (roles.find(function (role) { return element === role.title; }))
                return { value: true };
        };
        for (var i = 0; i < rolesList.length; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    }
    // Если роли не совпали
    return false;
};
export var adminPermission = function () {
    var roles = ['Administrator'];
    return compareRoles(roles);
};
// Присвоение пользователя
export var setCurrentUser = function (decoded) {
    return ({ type: SET_CURRENT_USER, payload: decoded });
};
// Выход из системы
export var logout = function () {
    localStorage.removeItem('token');
    // Удаление auth header в последующих запросах
    setAuthToken('');
    store.dispatch(setCurrentUser(null));
};
