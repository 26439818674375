import { jsx as _jsx } from "react/jsx-runtime";
import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Parser from 'html-react-parser';
export var Notifications = function () {
    var toast = useRef(null);
    var list = useSelector(function (state) { return state.notifications; }).list;
    useEffect(function () {
        var prepared = list.map(function (notification) {
            notification.detail = Parser(String(notification.detail));
            return notification;
        });
        if (toast.current)
            toast.current.show(prepared);
    }, [list]);
    return _jsx(Toast, { className: 'custom-notification', ref: toast });
};
